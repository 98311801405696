<template>
  <div>
    <router-link
      v-if="isGroupDestination"
      :to="{name: 'detail-group', params: {uid: groupeUID}}"
    >
      {{ proprio }}
    </router-link>
    <span v-else> {{ proprio }} </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TYPE_SMS_GROUPE, TYPE_SMS_UNIQUE } from '../../constants/group';
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        ...mapGetters({
            users: 'auth/users', 
            groupes: 'group/groups',
        }),
        proprio(){
            if(this.object.type === TYPE_SMS_UNIQUE){
                let p = this.users.find(item => item.uid === this.object.destinataire)
                if(p) return p.fullname + ' ['+p.telephone+']'
            }
            else if(this.object.type === TYPE_SMS_GROUPE){
                let g = this.groupes.find(item => item.uid === this.object.destinataire)
                if(g) return g.name + '['+g.members.length+']'
            }
            
            return '-'
        },
        isGroupDestination(){
            return this.object.type === TYPE_SMS_GROUPE
        },
        groupeUID(){
            if(this.isGroupDestination){
                let g = this.groupes.find(item => item.uid === this.object.destinataire)
                if(g) return g.uid
            }
            return null
        }
    }
}
</script>

<style lang="scss" scoped>
a{
    color: green;
}
</style>